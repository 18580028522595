import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { Box, Button, Container, DunkText, Flex, H1, Text } from "components"

function BranchSection({ title, subtitle, image, button, buttonUrl, scrollAmount }) {

  return (
    <Flex
      as="section"
      position="relative"
      flexDirection="column"
      justifyContent="flex-start"
      height="90%"
      pb={[5, 0]}
      css={css({
        overflow: "hidden",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(\"" +
          image +
          "\")",
        backgroundSize: "cover",
        backgroundPosition: "center",
      })}
    >
      <Container minHeight={["33%", 0]} secondBoxStyle={{ height: "100%" }} height="100%">
        <Box position="relative" height="100%">
          <Box position={["relative", "absolute"]}
            top={["auto", 0]}
            right={["auto", "auto", "auto", "0"]}
            width={["100%", "100%", "55%"]}
            maxWidth={[640, 640, null, null]}
            height="100%"
            style={{
              transform:
                "translate3d(0, " + (60 - (50 - scrollAmount * 50)) + "%, 0)",
            }}>
            <H1 as="h2" color="textReverse" minWidth="100%" position="relative">
              <DunkText lines={title ? title.split("\n") : []} />
            </H1>
            <Text
              children={subtitle}
              mt={[3, 4]}
              fontSize={[3, 4]}
              color="textReverse"
              position="relative"
              fontFamily="body"
            />
            {button && (
              <Box position="relative">
                <Button
                  children={button}
                  as={Link}
                  to={buttonUrl}
                  mt={4}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Flex>
  )
}

export default BranchSection
import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { Box, Button, Column, DunkText, Flex, H1, Text, Container } from "components"

const SustainableSection = ({
  title,
  subtitle,
  buttonText,
  buttonUrl,
  icon1,
  icon1Text,
  icon2,
  icon2Text,
  icon3,
  icon3Text,
  icon4,
  icon4Text,
  icon5,
  icon5Text,
  icon6,
  icon6Text,
  icon7,
  icon7Text,
  scrollAmount }) => (
  <Box position="relative" width="100%" height="fit-content(min(max-content, max(min-content, 90vh)))" bg="primary">
    <Box
      width="100%"
      height="100%"
      px={[0, null, 0, 0]}
    >
      <Container>
        <Flex flexWrap="wrap" top={["65px", "130px"]} bottom={["65px", "130px"]}>
          <Box display="flex" flexDirection={["column", "column", "column", "column"]} justifyContent="space-between" width="100%" py={["65px", "130px"]} px={[0, null, 0, null]} css={css({
            "@media screen and (min-width: 1250px)": {
              flexDirection: "row",
            },
          })}>
            <Column left flex={1} containerMaxWidth={[undefined, undefined, 680, 740]} containerMinWidth={[undefined, undefined, 620]} mb="50px" zindex={5} pr={[0, 0, 0, 40]}>
              <H1 as="h2" fontSize={["10vw", "64px", "64px", "72px"]}
                css={css({
                  "@media screen and (max-width: 340px)": {
                    fontSize: '9.5vw'
                  },
                })}>
                <DunkText lines={title ? title.split("\n") : []} />
              </H1>
              <Text
                children={subtitle}
                mt={[3, 4]}
                fontFamily="body"
                fontSize={[3, 4]}
              />
              {buttonText && (
                <Box position="relative">
                  <Button
                    children={buttonText}
                    as={Link}
                    to={buttonUrl}
                    mt={4}
                    css={css({
                      background: "white"
                    })}
                  />
                </Box>
              )}
            </Column>
            <Box mr="auto" display="flex" minHeight="100%" flexDirection="column" margin="auto">
              <Flex
                as="section"
                position="relative"
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignSelf="center"
                width="100%"
              >
                <Flex display="flex" flexDirection="row" justifyContent="space-evenly" py="15px">
                  <Box display="flex" flexBasis="50%" flexDirection="column" alignItems="center">
                    <img style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }} src={icon1} alt="icon1" width="75px" height="75px" />
                    <Text children={icon1Text} textAlign="center" fontFamily="body" fontSize={[4, 5, 4, 4]} />
                  </Box>
                  <Box display="flex" flexBasis="50%" flexDirection="column" alignItems="center">
                    <img style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }} src={icon2} alt="icon2" width="75px" height="75px" />
                    <Text children={icon2Text} fontFamily="body" fontSize={[4, 5, 4, 4]} />
                  </Box>
                </Flex>
                <Flex display="flex" flexDirection="row" justifyContent="space-evenly" py="15px">
                  <Box display="flex" flexBasis="50%" flexDirection="column" alignItems="center" mr={[0, 30, 30, 50]}>
                    <img style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }} src={icon3} alt="icon3" width="75px" height="75px" />
                    <Text children={icon3Text} textAlign="center" fontFamily="body" fontSize={[4, 5, 4, 4]} />
                  </Box>
                  <Box display="flex" flexBasis="50%" flexDirection="column" alignItems="center" mr={[0, 30, 30, 50]}>
                    <img style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }} src={icon4} alt="icon4" width="75px" height="75px" />
                    <Text children={icon4Text} textAlign="center" fontFamily="body" fontSize={[4, 5, 4, 4]} />
                  </Box>
                  <Box display={["none", "flex", "flex", "flex"]} flexBasis="50%" flexDirection="column" alignItems="center">
                    <img style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }} src={icon5} alt="icon5" width="75px" height="75px" />
                    <Text children={icon5Text} textAlign="center" fontFamily="body" fontSize={[4, 5, 4, 4]} />
                  </Box>
                </Flex>
                <Flex display="flex" flexDirection="row" justifyContent="space-evenly" py="15px">
                  <Box display={["flex", "none", "none", "none"]} flexBasis="50%" flexDirection="column" alignItems="center">
                    <img style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }} src={icon5} alt="icon5" width="75px" height="75px" />
                    <Text children={icon5Text} textAlign="center" fontFamily="body" fontSize={[4, 5, 4, 4]} />
                  </Box>
                  <Box display="flex" flexBasis="50%" flexDirection="column" alignItems="center" flexWrap="wrap">
                    <img style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }} src={icon6} alt="icon6" width="75px" height="75px" />
                    <Text children={icon6Text} width="10%" textAlign="center" fontFamily="body" fontSize={[4, 5, 4, 4]} />
                  </Box>
                  <Box display={["none", "flex", "flex", "flex"]} flexBasis="50%" flexDirection="column" alignItems="center">
                    <img style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }} src={icon7} alt="icon7" width="75px" height="75px" />
                    <Text children={icon7Text} textAlign="center" fontFamily="body" fontSize={[4, 5, 4, 4]} />
                  </Box>
                </Flex>
                <Flex display="flex" flexDirection="row" justifyContent="center" py="15px">
                  <Box display={["flex", "none", "none", "none"]} flexBasis="50%" flexDirection="column" alignItems="center">
                    <img style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }} src={icon7} alt="icon7" width="75px" height="75px" />
                    <Text children={icon7Text} textAlign="center" fontFamily="body" fontSize={[4, 5, 4, 4]} />
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  </Box >
)

export default SustainableSection
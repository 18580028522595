import React, { useState } from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { StaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"
import ReactMarkdown from "react-markdown"

import {
  Box,
  Button,
  Column,
  Container,
  Flex,
  H1,
  H2,
  H3,
  Section,
  Text,
} from "components"

const FlipFlop = ({
  title,
  subtitle,
  tagline,
  imgUrl,
  side,
  imgWidth,
  textBg,
  textColor,
}) => (
  <Box>
    <Flex flexWrap="wrap">
      <Flex
        justifyContent="center"
        alignItems="center"
        width={["100%", "100%", "100%", imgWidth]}
      >
        <img src={imgUrl} alt={""} width={"100%"} height={"100%"} />
      </Flex>
      <Flex
        justifyContent="center"
        order={["initial", side === "left" ? 1 : -1]}
        width={["100%", "100%", "100%", `calc(100% - ${imgWidth})`]}
        py={["65px", "130px"]}
        px={[4, null, 5, 6]}
        pl={
          side === "left"
            ? [4, null, 5, "calc(10% + 64px - 44px)"]
            : [4, null, 5, 6]
        }
        bg={textBg ? textBg : "transparent"}
        color={textColor}
      >
        <Box
          maxWidth={side === "left" ? undefined : "calc(800px + 118px)"}
          marginLeft={side === "left" ? undefined : "auto"}
          css={css({
            "@media(min-width: 1600px)": {
              paddingRight: side === "left" ? undefined : "160px",
            },
            "@media(min-width: 2000px)": {
              paddingRight: side === "left" ? undefined : "200px",
            },
          })}
        >
          <H1 children={title} fontSize={["10vw", 8, "64px", "60px", 9]} />
          <Text children={subtitle} mt={3} fontSize={[3, 4]} lineHeight={1.5} />
          {tagline && (
            <Text
              children={tagline}
              mt={3}
              fontSize={[3, 4]}
              lineHeight={1.5}
              fontWeight={700}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  </Box>
)

export default FlipFlop

import React, { useEffect, useState } from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { StaticQuery, graphql } from "gatsby"
import ScrollChanger from "react-scroll-changer"
import css from "@styled-system/css"
import "react-modal-video/scss/modal-video.scss"

import FeatureSection from "./_sections/_feature"
import SecondSection from "./_sections/_second"

import BranchSection from "./_sections/_branchSection"
import SustainableSection from "./_sections/_sustainable"
import DesignSection from "./_sections/_design"
import IndustriesSection from "./_sections/_industries"
import TrailerVideoSection from "./_sections/_trailerVideo"
import FlipFlop from "./_sections/_flipFlop"
import '../css/fonts.css'

function IndexPage() {
  // const [isIE, setIE] = useState(false)

  // const detectIE = function() {
  //   var ua = window.navigator.userAgent

  //   var msie = ua.indexOf("MSIE ")
  //   if (msie > 0) {
  //     // IE 10 or older => return version number
  //     return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10)
  //   }

  //   var trident = ua.indexOf("Trident/")
  //   if (trident > 0) {
  //     // IE 11 => return version number
  //     var rv = ua.indexOf("rv:")
  //     return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10)
  //   }

  //   // other browser
  //   return false
  // }

  // useEffect(() => {
  //   if (detectIE() !== false) {
  //     setIE(true)
  //   }
  // }, [])

  return (
    <StaticQuery
      query={graphql`
        query {
          page: datoCmsHome {
            useStaticImageInHero
            heroTitle
            heroSubtitle
            heroButtonText
            heroPrompt
            heroImage {
              url
            }
            heroVideo {
              url
            }
            fullScreenVideooo {
              url
            }
            youtubePopupButtonName
            fullscreenVideo
            fullscreenVideoTitle
            fullscreenVideoSubtitle
            panoramaLink

            digitalComponentConstructionTitle
            digitalComponentConstructionSubtitle
            digitalComponentConstructionTagline
            digitalComponentConstructionImage {
              url
            }
            digitizingInteriorConstructionTitle
            digitizingInteriorConstructionSubtitle
            digitizingInteriorConstructionTagline
            digitizingInteriorConstructionImage {
              url
            }

            branchBackgroundImage {
              url
            }
            branchSectionTitle
            branchSectionSubtitle
            branchSectionButton
            branchSectionButtonUrl
            revitTitle
            echoLogoIcon {
              url
            }
            revitSubtitle
            revitButtonName
            revitButtonUrl
            revitVideo
            sustainableTitle
            sustainableSubtitle
            sustainableButtonName
            sustainableButtonUrl
            sustainableIcon1 {
              url
            }
            sustainableIcon1Text
            sustainableIcon2 {
              url
            }
            sustainableIcon2Text
            sustainableIcon3 {
              url
            }
            sustainableIcon3Text
            sustainableIcon4 {
              url
            }
            sustainableIcon4Text
            sustainableIcon5 {
              url
            }
            sustainableIcon5Text
            sustainableIcon6 {
              url
            }
            sustainableIcon6Text
            sustainableIcon7 {
              url
            }
            sustainableIcon7Text
            limitlessTitle
            limitlessSubtitle
            limitlessButtonText
            limitlessImage {
              url
            }
            limitlessGallery {
              url
            }
            industry1Title
            industry1Subtitle
            industry1Image {
              url
            }
            industry2Title
            industry2Subtitle
            industry2Image {
              url
            }
            industry3Title
            industry3Subtitle
            industry3Image {
              url
            }
            industry4Title
            industry4Subtitle
            industry4Image {
              url
            }
            industry5Title
            industry5Subtitle
            industry5Image {
              url
            }
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            seoKeywords
          }
        }
      `}
      render={data => {
        if (data.page) {
          return (
            <>
              <HelmetDatoCms seo={data.page.seoMetaTags}>
                <meta name="keywords" content={data.page.seoKeywords} />
              </HelmetDatoCms>
              <FeatureSection
                title={data.page.heroTitle}
                subtitle={data.page.heroSubtitle}
                buttonText={data.page.heroButtonText}
                image={
                  data.page.useStaticImageInHero
                    ? data.page.heroImage &&
                      data.page.heroImage.url &&
                      data.page.heroImage.url
                    : null
                }
                video={
                  data.page.useStaticImageInHero
                    ? null
                    : data.page.heroVideo &&
                      data.page.heroVideo.url &&
                      data.page.heroVideo.url
                }
                prompt={data.page.heroPrompt}
                panorama={data.page.panoramaLink}
              />

              <FlipFlop
                imgWidth={"40%"}
                title={data.page.digitalComponentConstructionTitle}
                subtitle={data.page.digitalComponentConstructionSubtitle}
                tagline={data.page.digitalComponentConstructionTagline}
                imgUrl={data.page.digitalComponentConstructionImage?.url}
                side={"left"}
                textBg={"#595C5B"}
                textColor={"white"}
              />
              <FlipFlop
                imgWidth={"40%"}
                title={data.page.digitizingInteriorConstructionTitle}
                subtitle={data.page.digitizingInteriorConstructionSubtitle}
                tagline={data.page.digitizingInteriorConstructionTagline}
                imgUrl={data.page.digitizingInteriorConstructionImage?.url}
                side={"right"}
              />
              <SecondSection
                title={data.page.fullscreenVideoTitle}
                subtitle={data.page.fullscreenVideoSubtitle}
                buttonText={data.page.youtubePopupButtonName}
                video={
                  data.page.fullScreenVideooo && data.page.fullScreenVideooo.url
                }
                popupVideo={data.page.fullscreenVideo}
              />
              <TrailerVideoSection
                title={data.page.revitTitle}
                titleLogoUrl={data.page.echoLogoIcon?.url}
                subtitle={data.page.revitSubtitle} 
                buttonName={data.page.revitButtonName}
                buttonUrl={data.page.revitButtonUrl}
                video={data.page.revitVideo}
              />
              <ScrollChanger
                start={1}
                end={0}
                length={1}
                attached={false}
                wrapperStyle={{
                  height: "90vh",
                }}
                children={
                  <BranchSection
                    title={data.page.branchSectionTitle}
                    subtitle={data.page.branchSectionSubtitle}
                    button={data.page.branchSectionButton}
                    buttonUrl={data.page.branchSectionButtonUrl}
                    image={
                      data.page.branchBackgroundImage &&
                      data.page.branchBackgroundImage.url &&
                      data.page.branchBackgroundImage.url
                    }
                  />
                }
              />
              <SustainableSection
                title={data.page.sustainableTitle}
                subtitle={data.page.sustainableSubtitle}
                buttonText={data.page.sustainableButtonName}
                buttonUrl={data.page.sustainableButtonUrl}
                icon1={
                  data.page.sustainableIcon1 &&
                  data.page.sustainableIcon1.url &&
                  data.page.sustainableIcon1.url
                }
                icon1Text={data.page.sustainableIcon1Text}
                icon2={
                  data.page.sustainableIcon2 &&
                  data.page.sustainableIcon2.url &&
                  data.page.sustainableIcon2.url
                }
                icon2Text={data.page.sustainableIcon2Text}
                icon3={
                  data.page.sustainableIcon3 &&
                  data.page.sustainableIcon3.url &&
                  data.page.sustainableIcon3.url
                }
                icon3Text={data.page.sustainableIcon3Text}
                icon4={
                  data.page.sustainableIcon4 &&
                  data.page.sustainableIcon4.url &&
                  data.page.sustainableIcon4.url
                }
                icon4Text={data.page.sustainableIcon4Text}
                icon5={
                  data.page.sustainableIcon5 &&
                  data.page.sustainableIcon5.url &&
                  data.page.sustainableIcon5.url
                }
                icon5Text={data.page.sustainableIcon5Text}
                icon6={
                  data.page.sustainableIcon6 &&
                  data.page.sustainableIcon6.url &&
                  data.page.sustainableIcon6.url
                }
                icon6Text={data.page.sustainableIcon6Text}
                icon7={
                  data.page.sustainableIcon7 &&
                  data.page.sustainableIcon7.url &&
                  data.page.sustainableIcon7.url
                }
                icon7Text={data.page.sustainableIcon7Text}
              />
              <DesignSection
                title={data.page.limitlessTitle}
                subtitle={data.page.limitlessSubtitle}
                buttonText={data.page.limitlessButtonText}
                image={
                  data.page.limitlessImage &&
                  data.page.limitlessImage.url &&
                  data.page.limitlessImage.url
                }
                gallery={
                  data.page.limitlessGallery &&
                  data.page.limitlessGallery[0] &&
                  data.page.limitlessGallery
                }
              />
              {/* <IndustriesSection
                industry1Title={data.page.industry1Title}
                industry1Subtitle={data.page.industry1Subtitle}
                industry1Image={
                  data.page.industry1Image &&
                  data.page.industry1Image.url &&
                  data.page.industry1Image.url
                }
                industry2Title={data.page.industry2Title}
                industry2Subtitle={data.page.industry2Subtitle}
                industry2Image={
                  data.page.industry2Image &&
                  data.page.industry2Image.url &&
                  data.page.industry2Image.url
                }
                industry3Title={data.page.industry3Title}
                industry3Subtitle={data.page.industry3Subtitle}
                industry3Image={
                  data.page.industry3Image &&
                  data.page.industry3Image.url &&
                  data.page.industry3Image.url
                }
                industry4Title={data.page.industry4Title}
                industry4Subtitle={data.page.industry4Subtitle}
                industry4Image={
                  data.page.industry4Image &&
                  data.page.industry4Image.url &&
                  data.page.industry4Image.url
                }
                industry5Title={data.page.industry5Title}
                industry5Subtitle={data.page.industry5Subtitle}
                industry5Image={
                  data.page.industry5Image &&
                  data.page.industry5Image.url &&
                  data.page.industry5Image.url
                }
              /> */}
            </>
          )
        } else {
          return null
        }
      }}
    />
  )
}

export default IndexPage

import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { Box, Button, Column, DunkText, H1, Text, Container } from "components"
import { H2 } from "../../components/headings"
import Flex from "../../components/flex"
import Image from "../../templates/post/components/image"

const TrailerVideoSection = ({
  title,
  subtitle,
  titleLogoUrl,
  buttonName,
  buttonUrl,
  video,
  scrollAmount,
}) => {
  return (
    <Box position="relative" width="100%" bg="#F2F2F2">
      <Box
        display="flex"
        flexDirection={["column", "column", "column", "row"]}
        width="100%"
        justifyContent="space-between"
        py={["65px", "130px"]}
      >
        <Container>
          <Box
            display="flex"
            flexDirection={["column", "column", "column", "row"]}
            width="100%"
            justifyContent="space-between"
            css={css({
              "@media screen and (max-width: 1400px)": {
                flexDirection: "column",
              },
            })}
          >
            <Column
              left
              pb={["40px", "40px", "40px", "0"]}
              pr={[0, 0, 0, "64px"]}
              css={css({
                "@media screen and (max-width: 1400px)": {
                  paddingBottom: "40px",
                },
              })}
            >
              <Flex display="inline-flex" alignItems="center">
                <H1 as="span" fontSize={["7.5vw", "52px"]}>
                  <DunkText lines={title ? title.split("\n") : []} />
                </H1>
                <Box height={["7.5vw", "52px"]} marginLeft="12px">
                  <img src={titleLogoUrl} alt={""} height="100%" />
                </Box>
              </Flex>

              <Text
                children={subtitle}
                mt={3}
                fontSize={[3, 4]}
                lineHeight={1.5}
              />
              {buttonName && (
                <Button children={buttonName} as={Link} to={buttonUrl} mt={4} />
              )}
            </Column>
            <Box
              minHeight="100%"
              width={["100%", "100%", "100%", "50%"]}
              p={[0, 0, 0, 0]}
              css={css({
                "@media screen and (max-width: 1400px)": {
                  width: "100%",
                  margin: "auto",
                },
              })}
            >
              <div
                css={css({
                  position: "relative",
                  height: "0",
                  pb: "56.25%",
                })}
              >
                <iframe
                  src={video}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  css={css({
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                  })}
                />
              </div>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default TrailerVideoSection

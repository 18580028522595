import React from "react"

import { Box, Container, H2 } from "components"

const Image = ({ content }) => (
  <Box key={content.id}>
    {content.title && (
      <Container>
        <H2 children={content.title} mb={[3, 4]} />
      </Container>
    )}
    <img
      src={content.image.url}
      alt={content.image.alt}
      width={"100%"}
      height={"auto"}
    />
  </Box>
)

export default Image

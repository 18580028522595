import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { Box, Flex, H2, Text } from "components"

const Item = ({ heading, image, description, to }) => (
  <Box
    as={Link}
    to={to}
    position="relative"
    pb={["50%", "25%", "25%", "50%"]}
    bg="#000"
    css={css({
      overflow: "hidden",
      background: "#000",
      "h2": {
        textShadow: "1px 1px 4px rgba(0,0,0,0.3)",
      },
      ":before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage:
          'url("' +
          image +
          '?fit=crop&crop=center&max-w=300&max-h=300&auto=compress")',
        backgroundPosition: "center",
        backgroundSize: "cover",
        transition: "all 0.6s ease-in-out",
        transform: "scale(1,1)",
        opacity: 0.3,
      },
      ":hover": {
        ":before": {
          transform: "scale(1.05,1.05)",
          opacity: 0.8,
        },
      },
      "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
        "&:nth-of-type(1)": {
          msGridColumn: 1,
        },
        "&:nth-of-type(2)": {
          msGridColumn: 2,
        },
        "&:nth-of-type(3)": {
          msGridColumn: 3,
        },
      },
    })}
  >
    <Box
      position="absolute"
      top={0}
      right={0}
      width={[50, 70]}
      height={[30, 70]}
    >
      <svg
        viewBox="0 0 96 96"
        css={css({ width: "100%", color: "primary" })}
      >
        <path
          d="M37.585 37.585l2.427 2.427L80.324 0H75.45L37.585 37.585zM20.107 0L10.016 10.016l2.427 2.427L24.98 0h-4.873zm-9.224 0h4.873L7.85 7.849 5.42 5.42 10.883 0zM.826.826L1.66 0h4.873L3.254 3.254.826.826zm13.785 13.785L29.33 0h4.873L17.038 17.038l-2.427-2.427zm13.784 13.784L57.003 0h4.874L30.823 30.823l-2.428-2.428zm4.595 4.595L66.226 0H71.1L35.417 35.417 32.99 32.99zm-6.762-6.762l-2.427-2.427L47.779 0h4.874L26.228 26.228zm-4.595-4.595l-2.427-2.427L38.555 0h4.874L21.633 21.634zM89.547 0h-4.872L42.18 42.18l2.427 2.427L89.547 0zM96 39.374L67.582 67.582 65.15 65.15 96 34.53v4.843zm0-9.167L62.98 62.98l-2.423-2.423L96 25.377v4.83zm0-9.154L58.387 58.387l-2.424-2.424L96 16.223v4.83zm0-9.154L53.793 53.793l-2.424-2.424L96 7.069v4.83zm0-9.152L49.2 49.199l-2.425-2.424L93.901 0H96v2.747zm0 40.938v4.843L72.175 72.176l-2.43-2.431L96 43.685zm0 9.153v4.844L76.77 76.769l-2.431-2.43L96 52.838zm0 9.168v4.83L81.363 81.362 78.94 78.94 96 62.006zm0 9.154v4.83l-10.043 9.967-2.423-2.423L96 71.16zm0 9.153v4.83l-5.449 5.408-2.424-2.424L96 80.314zm0 9.154v4.83l-.855.848-2.424-2.424L96 89.467z"
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    </Box>
    <Flex
      position="absolute"
      top={0}
      left={0}
      flexDirection="column"
      justifyContent="center"
      width="100%"
      height="100%"
      px={[2, null, 3]}
    >
      <H2 children={heading} textAlign="center" color="muted" fontSize={[5, 4]} />
      <Text
        children={description}
        mt={[3, 4]}
        fontFamily="body"
        fontSize={[4, 5]}
        textAlign="center"
        color="primary"
      />
    </Flex>
  </Box>
)

const IndustriesSection = ({
  industry1Title,
  industry1Subtitle,
  industry1Image,
  industry2Title,
  industry2Subtitle,
  industry2Image,
  industry3Title,
  industry3Subtitle,
  industry3Image,
  industry4Title,
  industry4Subtitle,
  industry4Image,
  industry5Title,
  industry5Subtitle,
  industry5Image
}) => (
  <Box
    as="section"
    css={css({
      display: "grid",
      gridTemplateColumns: ["1fr", "1fr", "1fr", "repeat(5, 1fr)"],
      gridTemplateRows: ["1fr 1fr 1fr", "1fr 1fr 1fr", "1fr"],
      "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
        display: "-ms-grid",
        msGridColumns: "1fr 1fr 1fr",
        msGridRows: "1fr",
        height: "300px",
      },
    })}
  >
    <Item
      heading={industry1Title}
      image={industry1Image}
      description="Find the cure"
      to={"/vertical-markets/healthcare/"}
    />
    <Item
      heading={industry2Title}
      image={industry2Image}
      description="Let's meet up"
      to={"/vertical-markets/commercial-office/"}
    />
    <Item
      heading={industry3Title}
      image={industry3Image}
      description="Get schooled"
      to={"/vertical-markets/education/"}
    />
    <Item
      heading={industry4Title}
      image={industry4Image}
      description="Go shopping"
      to={"/vertical-markets/retail/"}
    />
    <Item
      heading={industry5Title}
      image={industry5Image}
      description="Go big"
      to={"/vertical-markets/industrial/"}
    />
  </Box>
)

export default IndustriesSection
import React, { useState } from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import { useSwipeable } from "react-swipeable"

import {
  Box,
  Column,
  Container,
  DunkText,
  Flex,
  H1,
  Text,
  Button,
} from "components"

import rightCursor from "../../../static/images/rightCursor.png"
import leftCursor from "../../../static/images/leftCursor.png"

function DesignSection({ title, subtitle, image, buttonText, gallery }) {
  const [activeGalleryItem, setActiveGalleryItem] = useState(0)
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      right()
    },
    onSwipedRight: () => {
      left()
    },
  })

  function left() {
    if (activeGalleryItem === 0) {
      setActiveGalleryItem(gallery.length - 1)
    } else {
      setActiveGalleryItem(activeGalleryItem - 1)
    }
  }
  function right() {
    if (activeGalleryItem === gallery.length - 1) {
      setActiveGalleryItem(0)
    } else {
      setActiveGalleryItem(activeGalleryItem + 1)
    }
  }

  return (
    <Flex
      as="section"
      position="relative"
      flexDirection="column"
      justifyContent={["flex-end", "center"]}
      height="100vh"
      pb={[5, 0]}
      css={css({
        overflow: "hidden",
        background:
          !gallery &&
          'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url("' +
            image +
            '")',
        backgroundSize: "cover",
        backgroundPosition: "center",
      })}
      {...handlers}
    >
      <Flex
        style={{
          transform: "translate3d(-" + activeGalleryItem * 100 + "%,0,0)",
          transition: "transform 400ms ease-in-out",
        }}
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        zIndex={-1}
      >
        {gallery.map((image, index) => (
          <Box
            height="100vh"
            minWidth="100vw"
            pb={[5, 0]}
            css={css({
              overflow: "hidden",
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url("' +
                image.url +
                "?fit=crop&crop=center&max-w=1600&max-h=1200&auto=compress" +
                '")',
              backgroundSize: "cover",
              backgroundPosition: "center",
            })}
            key={"galleryImage" + index}
          />
        ))}
      </Flex>
      <Box
        position="absolute"
        left={0}
        width="50%"
        right={"50%"}
        top={0}
        bottom={0}
        zIndex={0}
        css={css({ cursor: `url(${leftCursor}), auto` })}
        onClick={left}
      />
      <Box
        position="absolute"
        left="50%"
        width="50%"
        right={0}
        top={0}
        bottom={0}
        zIndex={0}
        css={css({ cursor: `url(${rightCursor}), auto` })}
        onClick={right}
      />
      <Container minHeight={["33%", 0]}>
        <Column right>
          <H1 as="h2" color="textReverse" position="relative">
            <DunkText lines={title ? title.split("\n") : []} />
          </H1>
          <Text
            children={subtitle}
            mt={[3, 4]}
            fontFamily="light"
            fontSize={[10, 11]}
            color="textReverse"
            position="relative"
          />
          {buttonText && (
            <Box position="relative">
              <Button
                children={buttonText}
                as={Link}
                to={"/components/finishes-materials/"}
                mt={4}
              />
            </Box>
          )}
        </Column>
      </Container>
      <Flex
        position="absolute"
        left={0}
        right={0}
        bottom={32}
        justifyContent="center"
      >
        {gallery.map((item, index) => (
          <Box
            css={css({
              width: 12,
              height: 12,
              borderRadius: 9999,
              marginRight: 2,
            })}
            bg={activeGalleryItem === index ? "primary" : "background"}
            key={"galleryDots" + index}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default DesignSection
